<script setup>
  import { ref, computed, onMounted, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import { useMediaQuery } from '@vueuse/core'

  import PlayerPairings from './PlayerPairings.vue';

  const isLargeScreen = useMediaQuery('(min-width: 1024px)')

  const store = useStore()

  const tournament = computed(() => store.getters.getTournament)
  const participantsByRank = computed(() => {
    return [...tournament.value.participants].sort((a, b) => a.rank - b.rank);
  });
  const registrationModalVisible = ref(false)
  const modalParticipant = ref(null)
  const participants_vp = computed(() => {
    return tournament.value.participants.reduce((acc, participant) => {
      const pairing = store.getters.getCurrentRound?.pairings.find(
        pairing => pairing.white_player_id === participant.id || pairing.black_player_id === participant.id
      );

      acc[participant.id] = pairing
        ? (pairing.white_player_id === participant.id
            ? pairing.white_virtual_points
            : pairing.black_virtual_points)
        : 0.0;

      return acc;
    }, {});
  })
  const hasRoundVP = computed(() => {
    return !!Object.entries(participants_vp.value).find(([key, value]) => value !== 0);
  });

  function showEncountersGrid(participant) {
    modalParticipant.value = participant;
    registrationModalVisible.value = true;
  }

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      registrationModalVisible.value = false;
    }
  };

  onMounted(() => {
    window.addEventListener('keydown', handleEscape);
  });

  onUnmounted(() => {
    window.removeEventListener('keydown', handleEscape);
  });
</script>

<template>
  <div id="standings-list"
  class="overflow-x-auto standings-pane bg-greyblue p-2 rounded-xl min-h-[70vh]">
    <div class="inline-block min-w-full">
      <div class="lg:flex gap-2 overflow-x-auto">
        <!-- Compact display -->
        <table v-if="store.getters.getDisplayFormat === 'compact'" class="w-full lg:w-1/2 text-sm font-light text-surface">
          <thead class="border-b border-neutral-200 font-medium">
            <tr>
              <th scope="col" class="text-start py-1" v-t="'rank'"></th>
              <th scope="col" class="text-start py-1" v-t="'name'"></th>
              <th scope="col" class="text-start py-1" v-t="'score'"></th>
              <th v-if="tournament.tie_breaker_1" scope="col" class="text-start py-1" v-t="`tournament.standings.${tournament.tie_breaker_1}`"></th>
              <th v-if="tournament.tie_breaker_2" scope="col" class="text-start py-1" v-t="`tournament.standings.${tournament.tie_breaker_2}`"></th>
              <th v-if="tournament.tie_breaker_3" scope="col" class="text-start py-1" v-t="`tournament.standings.${tournament.tie_breaker_3}`"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="participant in isLargeScreen ? participantsByRank.slice(0, Math.ceil(participantsByRank.length/2)) : participantsByRank"
            :key="participant.id"
            id="participant.id"
            class="border-b border-neutral-200 border-dotted"
            :class="{ 'bg-gray-700': participant === store.getters.getUserParticipant }">
              <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden font-medium text-start w-1/12">
                <div class="font-semibold">
                  {{ participant.rank }}
                </div>
              </td>
              <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-7/12">{{ participant.first_name }} {{ participant.last_name }}</td>
              <td class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-1/12">{{ participant.score }}</td>
              <td v-if="tournament.tie_breaker_1" class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-1/12">{{ participant.tie_breaker_1 }}</td>
              <td v-if="tournament.tie_breaker_2" class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-1/12">{{ participant.tie_breaker_2 }}</td>
              <td v-if="tournament.tie_breaker_3" class="whitespace-nowrap text-ellipsis truncate overflow-hidden w-1/12">{{ participant.tie_breaker_3 }}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="store.getters.getDisplayFormat === 'compact' && isLargeScreen" class="w-full lg:w-1/2 text-sm font-light text-surface">
          <thead class="hidden lg:table-header-group border-b border-neutral-200 font-medium">
            <tr>
              <th scope="col" class="text-start py-1" v-t="'rank'"></th>
              <th scope="col" class="text-start py-1" v-t="'name'"></th>
              <th scope="col" class="text-start py-1" v-t="'score'"></th>
              <th v-if="tournament.tie_breaker_1" scope="col" class="text-start py-1" v-t="`tournament.standings.${tournament.tie_breaker_1}`"></th>
              <th v-if="tournament.tie_breaker_2" scope="col" class="text-start py-1" v-t="`tournament.standings.${tournament.tie_breaker_2}`"></th>
              <th v-if="tournament.tie_breaker_3" scope="col" class="text-start py-1" v-t="`tournament.standings.${tournament.tie_breaker_3}`"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="participant in participantsByRank.slice(Math.ceil(participantsByRank.length/2))"
            :key="participant.id"
            id="participant.id"
            class="border-b border-neutral-200 border-dotted"
            :class="{ 'bg-gray-700': participant === store.getters.getUserParticipant }">
              <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate font-medium text-start w-1/12">
                <div class="font-semibold">
                  {{ participant.rank }}
                </div>
              </td>
              <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-7/12">{{ participant.first_name }} {{ participant.last_name }}</td>
              <td class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-1/12">{{ participant.score }}</td>
              <td v-if="tournament.tie_breaker_1" class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-1/12">{{ participant.tie_breaker_1 }}</td>
              <td v-if="tournament.tie_breaker_2" class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-1/12">{{ participant.tie_breaker_2 }}</td>
              <td v-if="tournament.tie_breaker_3" class="whitespace-nowrap overflow-hidden text-ellipsis truncate w-1/12">{{ participant.tie_breaker_3 }}</td>
            </tr>
          </tbody>
        </table>

          <!-- Normal display -->
        <table v-if="store.getters.getDisplayFormat !== 'compact'" class="min-w-full text-sm font-light text-surface">
          <thead class="border-b border-neutral-200 font-medium">
            <tr>
              <th scope="col" class="text-center px-1 py-4" v-t="'rank'"></th>
              <th scope="col" class="text-start px-2 py-4" v-t="'name'"></th>
              <th scope="col" class="text-start px-2 py-4" v-t="'score'"></th>
              <th v-if="tournament.tie_breaker_1" scope="col" class="text-start px-2 py-4">{{ $t(`tournament.standings.${tournament.tie_breaker_1}`) }}{{ tournament.tie_breaker_1.endsWith('_cut') || tournament.tie_breaker_1.endsWith('_median') ? tournament.modifier_tie_breaker_1 : "" }}</th>
              <th v-if="tournament.tie_breaker_2" scope="col" class="text-start px-2 py-4">{{ $t(`tournament.standings.${tournament.tie_breaker_2}`) }}{{ tournament.tie_breaker_2.endsWith('_cut') || tournament.tie_breaker_2.endsWith('_median') ? tournament.modifier_tie_breaker_2 : "" }}</th>
              <th v-if="tournament.tie_breaker_3" scope="col" class="text-start px-2 py-4">{{ $t(`tournament.standings.${tournament.tie_breaker_3}`) }}{{ tournament.tie_breaker_3.endsWith('_cut') || tournament.tie_breaker_3.endsWith('_median') ? tournament.modifier_tie_breaker_3 : "" }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="participant in participantsByRank"
            :key="participant.id"
            id="participant.id"
            class="border-b border-neutral-200 hover:bg-gray-500 hover:cursor-pointer"
            :class="{ 'bg-gray-700': participant === store.getters.getUserParticipant }"
            @click="showEncountersGrid(participant)">
              <td class="whitespace-nowrap px-1 py-4 font-medium text-center">
                <div v-if="[1, 2, 3].includes(participant.rank)"
                class="text-md"
                :class="{
                  'text-gold': participant.rank === 1,
                  'text-silver': participant.rank === 2,
                  'text-bronze': participant.rank === 3
                }">
                  <i class="fa-solid fa-trophy"></i>
                </div>
                <div v-else class="font-semibold">
                  {{ participant.rank }}
                </div>
              </td>
              <td class="whitespace-nowrap px-3 py-4">{{ participant.first_name }} {{ participant.last_name }}</td>
              <td v-if="hasRoundVP" class="whitespace-nowrap px-3 py-4 flex gap-1">
                <p class="min-w-8">{{ participant.score + participants_vp[participant.id] }}</p>
                <p>[{{ participant.score }}]</p>
              </td>
              <td v-else class="whitespace-nowrap px-3 py-4">{{ participant.score }}</td>
              <td v-if="tournament.tie_breaker_1" class="whitespace-nowrap px-3 py-4">{{ participant.tie_breaker_1 }}</td>
              <td v-if="tournament.tie_breaker_2" class="whitespace-nowrap px-3 py-4">{{ participant.tie_breaker_2 }}</td>
              <td v-if="tournament.tie_breaker_3" class="whitespace-nowrap px-3 py-4">{{ participant.tie_breaker_3 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Encounters modal -->
    <div @click="registrationModalVisible = false" v-show="registrationModalVisible" class="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
    <div v-show="registrationModalVisible"
    id="registration-modal" tabindex="-1"
    class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="relative p-4 mx-auto w-full">
        <!-- Modal content -->
        <div class="relative bg-dark-greyblue rounded-lg shadow max-h-[70vh] overflow-auto">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-lg font-semibold text-white">{{ modalParticipant?.first_name }} {{ modalParticipant?.last_name }}</h3>
            <button type="button" class="transition duration-200 text-gray-200 hover:bg-danger hover:text-gray-200 rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-red"
            data-modal-toggle="crud-modal"
            @click="registrationModalVisible = false">

              <div class="text-2xl">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </button>
          </div>

          <!-- Modal body -->
          <div class="p-3 md:p-5">
            <div class="text-center">
              <PlayerPairings :participant="modalParticipant" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
