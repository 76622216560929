<script setup>
  import { ref, computed } from 'vue'
  import { useToast } from 'primevue/usetoast';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  import { useConfirm } from "primevue/useconfirm";
  import { hasUserRoundRights, isRoundMissingResults } from "../entrypoints/utils.js";
  import api from '../entrypoints/api.js';

  const props = defineProps(['visibleRoundIndex'])

  const store = useStore();
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();

  const currentRound = computed(() => store.getters.getCurrentRound);
  const tournament = computed(() => store.getters.getTournament)

  const pendingCreateRound = ref(false);
  const isLastRoundMissingResults = computed(() => {
    if (currentRound.value) {
      return !!isRoundMissingResults(currentRound.value);
    }
    return false
  });

  function handleCreateRound(event, params) {
    if (store.getters.isParticipantsLicenceError && !currentRound.value) {
      confirmCreateRound(event, params);
    } else {
      createRound(params);
    }
  }

  function confirmCreateRound(event, params) {
    confirm.require({
      target: event.currentTarget,
      header: t('confirm.participantsLicenceErrorHeader'),
      message: t('confirm.participantsLicenceErrorMessage'),
      icon: 'pi pi-info-circle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.startTournament'),
      accept: () => createRound(params),
      group: 'dialog',
    });
  }

  async function createRound(params) {
    pendingCreateRound.value = true;
    const response = await api.createRound(tournament.value.id, params);
    pendingCreateRound.value = false;

    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.message, life: 3000 });
    } else {
      toast.add({ severity: 'success', summary: `New round created`, life: 3000 });
    }
  }
</script>

<template>
  <div v-if="hasUserRoundRights(tournament) && tournament.status != 'over'">
    <button v-show="!pendingCreateRound" class="primary-btn flex gap-2 items-center disabled:opacity-40 disabled:pointer-events-none"
    :disabled="visibleRoundIndex > 0 && isLastRoundMissingResults"
    @click="handleCreateRound($event, {})">
      <i class="fa-solid fa-plus"></i>
      <p class="hidden md:inline-block" v-t="'newRound'"></p>
    </button>
    <ProgressSpinner v-show="pendingCreateRound" style="width:50px; height:50px" strokeWidth="4" />
  </div>
</template>
