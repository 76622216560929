<script setup>
  import { onMounted, onUnmounted } from 'vue';

  const siteKey = document.querySelector('meta[name="recaptcha-site-key"]').getAttribute('content') || null;

  onMounted(() => {
    loadRecaptcha();
  });

  onUnmounted(() => {
    removeRecaptcha();
  });


  const loadRecaptcha = () => {
    if (!document.getElementById("recaptcha-script")) {
      const script = document.createElement("script");
      script.id = "recaptcha-script";
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        if (window.grecaptcha) {
          window.grecaptcha.ready();
        }
      };
      document.head.appendChild(script);
    }
  };

  const removeRecaptcha = () => {
    const script = document.getElementById("recaptcha-script");
    if (script) script.remove();

    // Supprime l'icône en bas à droite
    document.querySelectorAll(".grecaptcha-badge").forEach((el) => el.remove());
  };
</script>

<template>

</template>
