<script setup>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import api from "../entrypoints/api.js";

  import PlayerPairings from './PlayerPairings.vue';
  import TournamentShowTableResult from './TournamentShowTableResult.vue';

  const store = useStore();

  const round = computed(() => store.getters.getCurrentRound)
  const pairing = computed(() => store.getters.getUserCurrentPairing)
  const white_player = computed(() => store.getters.getTournament.participants.find(participant => { return participant.id === pairing.value.white_player_id }))
  const black_player = computed(() => store.getters.getTournament.participants.find(participant => { return participant.id === pairing.value.black_player_id }))
  const isAuthenticated = computed(() => store.getters.isAuthenticated)

  const whiteActiveColor = computed(() => {
    return {
      'border-black': white_player.value != store.getters.getUserParticipant,
      'shadow-success border-success': white_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
      'shadow-warning border-warning': white_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && !store.getters.getUserParticipant.active,
      'shadow-danger border-danger': white_player.value === store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin,
    }
  })
  const blackActiveColor = computed(() => {
    return {
      'border-black': black_player.value != store.getters.getUserParticipant,
      'shadow-success border-success': black_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && store.getters.getUserParticipant.active,
      'shadow-warning border-warning': black_player.value === store.getters.getUserParticipant && store.getters.getUserParticipant.checkin && !store.getters.getUserParticipant.active,
      'shadow-danger border-danger': black_player.value === store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin,
    }
  })

  async function toggleParticipantActivation(participant) {
    const response = await api.toggleParticipantActivation(participant);

    if(response.error) {
      toast.add({ severity: "error", summary: response.error.message, life: 3000 });
    }
  }
</script>

<template>
  <div>
    <div class="bg-greyblue rounded-xl p-3 mb-4">
      <div v-if="round && pairing && store.getters.getTournament.status != 'over'" class="flex flex-col items-center w-full">
        <div class="border-b border-neutral-200 font-medium w-full text-center mb-4">
          <h1 v-if="pairing.result.endsWith('_bye')" class="text-3xl mb-2">Bye</h1>
          <h1 v-else class="text-3xl mb-2">{{ $t('table') }} {{ pairing.table }}</h1>
          <h6 class="text-lg">{{ $t('Round') }} {{ round.index }}</h6>
        </div>
        <div class="flex flex-col lg:flex-row items-center lg:justify-evenly gap-4 mb-2">
          <div v-if="pairing.white_player_id"
          id="white-card"
          class="relative bg-dark-greyblue rounded-xl shadow-lg border-2 p-2 overflow-hidden w-72"
          :class="whiteActiveColor">
            <div class="">
              <p class="font-bold text-xl">{{ white_player.first_name || "-" }}</p>
              <p class="font-bold text-xl">{{ white_player.last_name || "-" }}</p>
              <p class="text-lg">{{ pairing.white_elo }}</p>
            </div>
            <div class="mt-2 text-info italic w-36">
              <div class="flex justify-between underline mb-2">
                <span>{{ $t('score') }} :</span>
                <span>{{ pairing.white_score }}</span>
              </div>
              <div class="flex justify-between" v-if="store.getters.getTournament.tie_breaker_1">
                <span>{{ $t(`tournament.standings.${store.getters.getTournament.tie_breaker_1}`) }} :</span>
                <span>{{ pairing.white_tie_breaker_1 }}</span>
              </div>
              <div class="flex justify-between" v-if="store.getters.getTournament.tie_breaker_2">
                <span>{{ $t(`tournament.standings.${store.getters.getTournament.tie_breaker_2}`) }} :</span>
                <span>{{ pairing.white_tie_breaker_2 }}</span>
              </div>
              <div class="flex justify-between" v-if="store.getters.getTournament.tie_breaker_3">
                <span>{{ $t(`tournament.standings.${store.getters.getTournament.tie_breaker_3}`) }} :</span>
                <span>{{ pairing.white_tie_breaker_3 }}</span>
              </div>
            </div>
            <div class="absolute flex flex-col items-center bottom-1 right-3 font-light z-10 text-white opacity-80 -rotate-12">
              <i class="fa fa-chess-bishop" style="font-size: 6rem"></i>
              <p class="text-2xl font-semibold">{{ $t('WHITE') }}</p>
            </div>
          </div>

          <TournamentShowTableResult />

          <div v-if="pairing.black_player_id"
          id="black-card"
          class="relative bg-dark-greyblue rounded-xl shadow-lg border-2 p-2 overflow-hidden w-72"
          :class="blackActiveColor">
            <div class="">
              <p class="font-bold text-xl">{{ black_player.first_name || "-" }}</p>
              <p class="font-bold text-xl">{{ black_player.last_name || "-" }}</p>
              <p class="text-lg">{{ pairing.black_elo }}</p>
            </div>
            <div class="mt-2 text-info italic w-36">
              <div class="flex justify-between underline mb-2">
                <span>{{ $t('score') }} :</span>
                <span>{{ pairing.black_score }}</span>
              </div>
              <div class="flex justify-between" v-if="store.getters.getTournament.tie_breaker_1">
                <span>{{ $t(`tournament.standings.${store.getters.getTournament.tie_breaker_1}`) }} :</span>
                <span>{{ pairing.black_tie_breaker_1 }}</span>
              </div>
              <div class="flex justify-between" v-if="store.getters.getTournament.tie_breaker_2">
                <span>{{ $t(`tournament.standings.${store.getters.getTournament.tie_breaker_2}`) }} :</span>
                <span>{{ pairing.black_tie_breaker_2 }}</span>
              </div>
              <div class="flex justify-between" v-if="store.getters.getTournament.tie_breaker_3">
                <span>{{ $t(`tournament.standings.${store.getters.getTournament.tie_breaker_3}`) }} :</span>
                <span>{{ pairing.black_tie_breaker_3 }}</span>
              </div>
            </div>
            <div class="absolute flex flex-col items-center bottom-0.5 right-3 font-light z-10 text-black -rotate-12">
              <i class="fa fa-chess-rook" style="font-size: 6rem"></i>
              <p class="text-2xl font-bold">{{ $t('BLACK') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="flex flex-col gap-2 flex-1 text-center">
        <h2 v-if="store.getters.getTournament.status === 'over'" v-t="'tableTournamentOverMessage'" class="text-xl self-center"></h2>
        <div v-else>
          <h2 v-if="round && store.getters.getUserParticipant" v-t="'tableDeactivatedPlayerMessage'" class="text-xl self-center"></h2>
          <h2 v-else-if="!round && store.getters.getUserParticipant" v-t="'tableNoRoundMessage'" class="text-xl"></h2>
          <h2 v-else-if="isAuthenticated" class="self-center text-xl" v-t="'tableUnregisteredPlayerMessage'"></h2>
          <div v-else>
            <h2 class="text-2xl flex-1" v-t="'tableUnidentifiedUserMessage'"></h2>
            <RouterLink :to="{ path: '/login', query: { redirect: $route.fullPath }}">
              <button class="primary-btn mt-4" v-t="'users.login'"></button>
            </RouterLink>
          </div>
          <h2 v-if="store.getters.getUserParticipant && !store.getters.getUserParticipant.checkin" class="text-xl self-center" v-html="$t('tournament.table.checkedOutMessage')"></h2>
          <div v-else-if="store.getters.getUserParticipant && !store.getters.getUserParticipant?.active" class="self-center">
            <p class="text-xl mb-6" v-html="$t('tournament.table.deactivatedMessage')"></p>
            <button @click="toggleParticipantActivation(store.getters.getUserParticipant)" class="success-btn text-lg">{{ $t('tournament.table.activateBtn') }}</button>
          </div>
          <div v-else-if="store.getters.getUserParticipant && store.getters.getUserParticipant?.active" class="self-center">
            <p class="text-xl mb-6" v-html="$t('tournament.table.activatedMessage')"></p>
            <button @click="toggleParticipantActivation(store.getters.getUserParticipant)" class="warning-btn text-lg">{{ $t('tournament.table.deactivateBtn') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="store.getters.getUserParticipant" class="bg-greyblue rounded-xl p-3 justify-center">
      <div class="text-center w-full mb-4">
        <h1 class="text-xl font-bold">{{ $t('tournament.table.opponents') }}</h1>
      </div>
      <PlayerPairings :participant="store.getters.getUserParticipant" />
    </div>
  </div>
</template>
