<script setup>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex'
  import { isUserReferee } from '../entrypoints/utils.js'
  import { useI18n } from 'vue-i18n';
  import { useToast } from 'primevue/usetoast';
  import api from '../entrypoints/api.js';

  const toast = useToast();
  const store = useStore();
  const props = defineProps(['round', 'pairing']);
  const emit = defineEmits(['toggleParticipantMenu'])
  const { t } = useI18n();

  const pairingResult = ref(props.pairing.result)
  const whitePlayer = computed(() => { return store.getters.getTournament.participants.find(participant => { return participant.id === props.pairing.white_player_id }) });
  const blackPlayer = computed(() => { return store.getters.getTournament.participants.find(participant => { return participant.id === props.pairing.black_player_id }) });

  const whiteActiveColor = computed(() => {
    return {
      'border-2 border-success': whitePlayer.value && whitePlayer.value.checkin && whitePlayer.value.active,
      'border-2 border-warning': whitePlayer.value && whitePlayer.value.checkin && !whitePlayer.value.active,
      'border-2 border-danger': whitePlayer.value && !whitePlayer.value.checkin,
    }
  })
  const blackActiveColor = computed(() => {
    return {
      'border-2 border-success': blackPlayer.value && blackPlayer.value.checkin && blackPlayer.value.active,
      'border-2 border-warning': blackPlayer.value && blackPlayer.value.checkin && !blackPlayer.value.active,
      'border-2 border-danger': blackPlayer.value && !blackPlayer.value.checkin,
    }
  })

  const resultsHash = {
    'conflict': t('pairingResultConflict'),
    'pending': t('pairingResultPending'),
    null: t('pairingResultPending'),
    'white_win': t('pairingResultWhiteWins'),
    'white_win_by_forfeit': t('pairingResultWhiteWinsByForfeit'),
    'black_win': t('pairingResultBlackWins'),
    'black_win_by_forfeit': t('pairingResultBlackWinsByForfeit'),
    'draw': t('pairingResultDraw'),
    'double_forfeit': t('pairingResultDoubleForfeit'),
    'full_bye': t('pairingResultFullBye'),
    'half_bye': t('pairingResultHalfBye'),
    'zero_bye': t('pairingResultZeroBye'),
    'exempt': t('pairingResultExempt'),
  }

  watch(() => props.pairing, () => {
    pairingResult.value = props.pairing.result;
  })

  const winner = computed(() => {
    if(pairingResult.value === 'white_win' || pairingResult.value === 'white_win_by_forfeit') {
      return "white"
    } else if(pairingResult.value === "black_win" || pairingResult.value === "black_win_by_forfeit") {
      return "black"
    } else if(pairingResult.value === "draw") {
      return "draw"
    } else {
      return null
    }
  })

  function getResult(key) {
    if (key === null || !(key in resultsHash))
      return resultsHash['pending'];

    return resultsHash[key];
  }

  async function updateResultArbiter() {
    const response = await api.updatePairingResult({ pairingId: props.pairing.id, result: pairingResult.value, override: true })

    if (response.error) {
      toast.add({ severity: "error", summary: response.error.message, life: 3000 });
    }
  }

  const resultToTable = {
    "pending": "-",
    "conflict": "-",
    "draw": "1/2-1/2",
    "white_win": "1-0",
    "white_win_by_forfeit": "1-F",
    "black_win": "0-1",
    "black_win_by_forfeit": "F-1",
    "double_forfeit": "F-F",
    "full_bye": "1 bye",
    "half_bye": "1/2 bye",
    "zero_bye": "0 bye",
    "exempt": "1 bye",
  }
</script>

<template>
  <td v-if="store.getters.getDisplayFormat === 'compact'" class="whitespace-nowrap w-1/12">{{ pairing.table }}</td>
  <td v-if="store.getters.getDisplayFormat === 'compact'" class="whitespace-nowrap w-5/12"
  :class="{ 'line-through': !whitePlayer?.active || !whitePlayer?.checkin }">
  {{ whitePlayer ? whitePlayer.first_name : "-" }} {{ whitePlayer ? whitePlayer.last_name : "-" }}
  </td>
  <td v-if="store.getters.getDisplayFormat === 'compact'" class="whitespace-nowrap w-1/12 px-1">{{ resultToTable[pairing.result] }}</td>
  <td v-if="store.getters.getDisplayFormat === 'compact'" class="whitespace-nowrap w-5/12"
  :class="{ 'line-through': !blackPlayer?.active || !blackPlayer?.checkin }">
    {{ blackPlayer ? blackPlayer.first_name : "-" }} {{ blackPlayer ? blackPlayer.last_name : "-" }}
  </td>

  <div v-else class="flex md:flex-row gap-3 justify-between bg-purpleblue rounded-xl py-2 px-3 mb-3"
    :class="{
      'flex-col': !pairing.result.endsWith('_bye'),
    }">
    <div class="flex gap-3 justify-between flex-grow items-center md:max-w-xs min-w-xs">
      <div v-if="!pairing.result.endsWith('_bye')" class="flex gap-3">
        <p class="m-0 text-lg">{{ $t('table') }} {{ pairing.table }}</p>
        <p class="text-xl">|</p>
      </div>
      <select v-if="isUserReferee(store.getters.getTournament) && round.status == 'published'"
      v-model="pairingResult"
      :disabled="pairingResult === 'exempt'"
      class="bg-deep-greyblue hover:bg-dark-greyblue text-md text-center border-1 border-light-grey rounded-md object-center flex-grow p-2 disabled:pointer-events-none"
      @change="updateResultArbiter">
        <option value="conflict" v-if="pairingResult === 'conflict'">{{ getResult('conflict') }}</option>
        <option value='pending' :selected="!pairingResult" v-if="whitePlayer && blackPlayer">{{ getResult('pending') }}</option>
        <option value="white_win" v-if="whitePlayer && blackPlayer">{{ getResult('white_win') }}</option>
        <option value="black_win" v-if="whitePlayer && blackPlayer">{{ getResult('black_win') }}</option>
        <option value="draw" v-if="whitePlayer && blackPlayer">{{ getResult('draw') }}</option>
        <option value="white_win_by_forfeit" v-if="whitePlayer && blackPlayer">{{ getResult('white_win_by_forfeit') }}</option>
        <option value="black_win_by_forfeit" v-if="whitePlayer && blackPlayer">{{ getResult('black_win_by_forfeit') }}</option>
        <option value="double_forfeit" v-if="whitePlayer && blackPlayer">{{ getResult('double_forfeit') }}</option>
        <option value="full_bye" v-if="!whitePlayer || !blackPlayer" :disabled="whitePlayer.full_byes_count >= store.getters.getTournament.max_full_byes">{{ getResult('full_bye') }}</option>
        <option value="half_bye" v-if="!whitePlayer || !blackPlayer" :disabled="whitePlayer.half_byes_count >= store.getters.getTournament.max_half_byes">{{ getResult('half_bye') }}</option>
        <option value="zero_bye" v-if="!whitePlayer || !blackPlayer">{{ getResult('zero_bye') }}</option>
        <option value="exempt" v-if="pairingResult === 'exempt'">{{ getResult('exempt') }}</option>
      </select>
      <p v-else class="bg-deep-greyblue text-md text-center border-1 border-light-grey rounded-md object-center flex-grow p-2">
        {{ getResult(pairing.result) }}
      </p>
    </div>

    <div class="flex gap-3 flex-grow min-w-55%">
      <button :disabled="!isUserReferee(store.getters.getTournament) || round.status != 'published' || !whitePlayer || !pairing.black_player_id"
      @click="emit('toggleParticipantMenu', pairing, 'white', $event)"
      :class="[whiteActiveColor, whitePlayer ? 'bg-gray-200' : 'bg-deep-greyblue', !pairing.black_player_id ? 'w-full' : 'flex gap-2 basis-1/2' ]"
      class="items-center rounded-md ported-shadow text-gray-900 justify-center p-2 text-center align-center">
        <i v-if="winner === 'white'" class="fa-solid fa-star text-xs text-black"></i>
        <i v-if="winner === 'draw'" class="fa-solid fa-minus text-md"></i>
        <p v-if="whitePlayer" class="m-0" :class="{ 'line-through': !whitePlayer.active || !whitePlayer.checkin }">{{ whitePlayer.first_name }} {{ whitePlayer.last_name }}</p>
        <div class="flex align-center">
          <i v-if="pairing.white_illegal_moves > 0" class="text-danger fa-solid fa-ban text-xl my-auto mx-1"></i>
          <i v-if="pairing.white_illegal_moves == 2" class="text-danger fa-solid fa-ban text-xl"></i>
          <p v-if="pairing.white_illegal_moves > 2" class="text-danger text-lg">x{{ pairing.white_illegal_moves }}</p>
        </div>
      </button>
      <button v-if="pairing.black_player_id"
      :disabled="!isUserReferee(store.getters.getTournament) || round.status != 'published' || !blackPlayer"
      @click="emit('toggleParticipantMenu', pairing, 'black', $event)"
      :class="[blackActiveColor, blackPlayer ? 'bg-black' : 'bg-deep-greyblue']"
      class="flex gap-2 items-center basis-1/2 rounded-md ported-shadow text-light-grey justify-center p-2 text-center align-center">
        <div class="flex align-center">
          <i v-if="pairing.black_illegal_moves > 0" class="text-danger fa-solid fa-ban text-xl my-auto mx-1"></i>
          <i v-if="pairing.black_illegal_moves == 2" class="text-danger fa-solid fa-ban text-xl"></i>
          <p v-if="pairing.black_illegal_moves > 2" class="text-danger text-lg">x{{ pairing.black_illegal_moves }}</p>
        </div>
        <p v-if="blackPlayer" class="m-0" :class="{ 'line-through': !blackPlayer.active || !blackPlayer.checkin }">{{ blackPlayer.first_name }} {{ blackPlayer.last_name }}</p>
        <i v-if="winner === 'black'" class="fa-solid fa-star text-xs text-white"></i>
        <i v-if="winner === 'draw'" class="fa-solid fa-minus text-md"></i>
      </button>
    </div>
  </div>
</template>
