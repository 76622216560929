<script setup>
  import { ref, watch, reactive, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useConfirm } from "primevue/useconfirm";
  import { isRoundMissingResults, hasUserRoundRights } from "../entrypoints/utils.js";
  import { useI18n } from 'vue-i18n';
  import { useToast } from 'primevue/usetoast';
  import api from '../entrypoints/api.js';
  import { handleFileDownload, isUserAdmin } from '../entrypoints/utils.js';
  import { useMediaQuery } from '@vueuse/core';

  import FileUpload from 'primevue/fileupload';
  import RoundPairing from './RoundPairing.vue';
  import DeleteRoundBtn from './DeleteRoundBtn.vue';
  import CreateRoundBtn  from './CreateRoundBtn.vue';

  const store = useStore();
  const toast = useToast();
  const confirm = useConfirm();
  const { t } = useI18n();

  const participantMenuRef = ref();
  const countdown = ref(0);
  const notifyPlayersEnabled = ref(true);
  const NOTIF_INTERVAL = 120;
  const isLargeScreen = useMediaQuery('(min-width:1024px)');
  const roundFileUpload = ref([]);
  const currentRound = computed(() => store.getters.getCurrentRound);
  const sortedRounds = computed(() => {
    const tmpRounds = [...store.getters.getTournament.rounds].sort((a, b) => a.index - b.index)

    if(!hasUserRoundRights)
      tmpRounds.filter(round => round.status !== "draft")

    return tmpRounds
  });
  const sortedPairings = computed(() => {
    return sortedRounds.value[visibleRoundIndex.value - 1].pairings.filter(pairing => pairing.table > 0).sort((a, b) => a.table - b.table)
  })
  const deactivatedPlayerPairings = computed(() => {
    return sortedRounds.value[visibleRoundIndex.value - 1].pairings.filter(pairing => pairing.table === 0)
  })
  const hideOverGames = ref(false);
  const showUnpairedPlayers = ref(false);
  const visibleRoundIndex = ref(sortedRounds.value.length)
  const roundDisplayed = computed(() => sortedRounds.value[visibleRoundIndex.value - 1])

  watch(sortedRounds, () => {
    if (visibleRoundIndex.value >= sortedRounds.value.length - 1 ) {
      visibleRoundIndex.value = sortedRounds.value.length
    }
  });

  watch(visibleRoundIndex, () => {
    hideOverGames.value = false;
  });

  async function publishRound(round) {
    const response = await api.publishRound(round);

    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.message, life: 3000 })
    } else {
      toast.add({ severity: 'success', summary: `Round ${round.index} published`, life: 3000 })
    }
  }

  async function downloadRound(round) {
    try {
      const response = await api.get(`/rounds/${round.id}/download`, {
        headers: {
          'Accept': 'text/csv',
        },
        responseType: "blob",
      });

      handleFileDownload(response, `${store.getters.getTournament.title}_round_${round.index}.csv`);
    } catch (error) {
      console.error(error);
      toast.add({ severity: 'error', summary: 'Error downloading round file', detail: error.message, life: 3000 });
    }
  }

  async function downloadRoundTemplate() {
    try {
      const response = await api.get('/download_custom_round_template', {
        responseType: "blob",
      });

      handleFileDownload(response, 'round_template.csv')
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading round template', life: 3000 });
    }
  }

  function uploadRound(round) {
    const files = roundFileUpload.value[round.index].files;

    if (files.length === 0) {
      console.error('No file selected.');
      return;
    }

    const formData = new FormData();
    formData.append('file', files[0]);

    api
      .put(`/rounds/${round.id}/upload`, formData)
      .then(response => {
        toast.add({ severity: 'success', summary: 'Successfully uploaded round', life: 3000 })
      })
      .catch(error => {
        console.error('Error uploading file:', error);
        toast.add({ severity: 'error', summary: error.message, life: 3000 })
      });
  }

  function resetRoundPairings(round) {
    api
      .put(`/rounds/${round.id}/reset_pairings`)
      .then(response => {
        toast.add({ severity: 'success', summary: 'Reset successfull', life: 3000 })
      })
      .catch(error => {
        console.error('Reset error:', error);
        toast.add({ severity: 'error', summary: error.message, life: 3000 })
      });
  }

  async function downloadTournamentPlayers() {
    try {
      const response = await api.get(`/tournaments/${store.getters.getTournament.id}/download_players`, {
        responseType: "blob",
        headers: {
          'Accept': 'text/csv',
        }
      });

      handleFileDownload(response, `${store.getters.getTournament.title}_players.csv`);
    } catch (error) {
      toast.add({ severity: 'error', summary: 'Error downloading players', life: 3000 });
      console.error(error);
    }
  }

  function setIllegalMoves(pairingId, color, illegalMoves) {
    api.put(`/pairings/${pairingId}/set_illegal_moves`, {color: color, illegal_moves: illegalMoves})
    .then()
    .catch(error => {
      toast.add({ severity: "error", summary: error.message, life: 3000 })
    })
  }

  const participantMenu = reactive({
    pairing: {},
    color: null,
    illegalMoves: 0,
  })
  const items = computed(() => {
    const itemsList = [{
      label: "Illegal moves",
      items: [{
        label: "Add",
        icon: "fa-solid fa-plus",
        command: () => setIllegalMoves(participantMenu.pairing.id, participantMenu.color, participantMenu.illegalMoves + 1)
      }]
    }]

    if (participantMenu.illegalMoves > 0) {
      itemsList[0].items.push({
        label: "Remove",
        icon: "fa-solid fa-minus",
        command: () => setIllegalMoves(participantMenu.pairing.id, participantMenu.color, participantMenu.illegalMoves - 1)
      })
    }
    return itemsList
  })

  const toggleParticipantMenu = (pairing, color, event) => {
    participantMenu.pairing = pairing
    participantMenu.color = color
    if (participantMenu.color == "white") {
      participantMenu.illegalMoves = pairing.white_illegal_moves;
    } else {
      participantMenu.illegalMoves = pairing.black_illegal_moves;
    }
    participantMenuRef.value.toggle(event)
  }

  function confirmNotifyRemainingPlayers(event) {
    confirm.require({
      target: event.currentTarget,
      header: t('confirm.notifyPlayersHeader'),
      message: t('confirm.notifyPlayersMessage'),
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: t('confirm.cancel'),
      acceptLabel: t('confirm.notifyPlayersConfirm'),
      accept: notifyRemainingPlayers,
      group: 'dialog',
    });
  }

  async function notifyRemainingPlayers() {
    const response = await api.notifyRemainingPlayers(store.getters.getTournament.id);
    if (!response.error) {
      notifyPlayersEnabled.value = false;
      countdown.value = NOTIF_INTERVAL;
      const interval = setInterval(() => {
        countdown.value -= 1;
        if(countdown.value <= 0) {
          notifyPlayersEnabled.value = true;
          clearInterval(interval);
        }
      }, 1000);
      toast.add({ severity: 'success', summary: `Players notified`, life: 3000 });
    }
    if (response.error) {
      toast.add({ severity: 'error', summary: response.error.message, life: 3000 });
    }
  }

  function toggleAutoRounds() {
    api.put(`/tournaments/${store.getters.getTournament.id}/set_auto_rounds`, {
      auto_round: !store.getters.getTournament.auto_rounds,
    })
    .catch((err) => {
      toast.add({ severity: "error", summary: err.message, life: 6000 });
    });
  }
</script>

<template>
  <div class="bg-greyblue min-h-[70vh] rounded-xl">
    <Menu ref="participantMenuRef" id="overlay_menu" :model="items" :popup="true" />
    <div class="py-3 px-4">
      <div class="rounded-lg min-h-[70vh] flex flex-col">
        <div class="flex items-center justify-between">
          <button @click="visibleRoundIndex--"
          class="disabled:opacity-50 disabled:pointer-events-none"
          :disabled="!currentRound || visibleRoundIndex == 1">
            <div class="bg-deep-greyblue text-xl w-8 text-center border-1 border-light-grey rounded-md object-center">
              <i class="fa-solid fa-chevron-left object-center"></i>
            </div>
          </button>
          <DeleteRoundBtn :visibleRoundIndex="visibleRoundIndex" :roundDisplayed="roundDisplayed" />
          <select v-model="visibleRoundIndex"
          class="bg-deep-greyblue hover:bg-dark-greyblue text-light-grey rounded-md">
            <option v-for="(round) in sortedRounds" :key="round.id"
            :value="round.index">
              {{ $t('Round') }} {{ round.index }}
              <div>
                <i class="fa-solid fa-chevron-down"></i>
              </div>
            </option>
            <option v-if="!currentRound" value=''>------</option>
          </select>
          <CreateRoundBtn :visibleRoundIndex="visibleRoundIndex" />
          <button @click="visibleRoundIndex++"
          class="disabled:opacity-50 disabled:pointer-events-none"
          :disabled="!currentRound || visibleRoundIndex == sortedRounds.length">
            <div class="bg-deep-greyblue text-xl w-8 text-center border-1 border-light-grey rounded-md object-center">
              <i class="fa-solid fa-chevron-right"></i>
            </div>
          </button>
        </div>
        <div class="w-full my-2 border-b border-gray-500 self-center"></div>

        <!-- Actions button -->
        <div class="mb-3 flex flex-wrap w-full items-center gap-3 justify-evenly text-center"
        v-show="visibleRoundIndex > 0 || !currentRound">
          <button @click="confirmNotifyRemainingPlayers"
          v-if="store.getters.getTournament.status == 'running' && isUserAdmin(store.getters.getTournament)"
          v-show="visibleRoundIndex > 0 && visibleRoundIndex == currentRound?.index"
          class="warning-btn flex items-center gap-2 disabled:opacity-40 disabled:pointer-events-none"
          :disabled="!notifyPlayersEnabled">
            <div class="flex items-center">
              <i class="fa-solid fa-bell"></i>
            </div>
            <p v-if="notifyPlayersEnabled" class="hidden lg:block">{{ $t('notifyPlayersAction') }}</p>
            <p v-if="!notifyPlayersEnabled">{{ countdown }} s</p>
          </button>
          <button v-if="hasUserRoundRights(store.getters.getTournament)"
          v-show="currentRound"
          @click="downloadRound(roundDisplayed)"
          class="button bg-stone-600 flex items-center gap-2">
            <i class="fa-solid fa-download"></i>
            <p class="hidden lg:block">{{ $t('tournament.edit.downloadRoundAction')}}</p>
          </button>
          <button v-if="hasUserRoundRights(store.getters.getTournament) && store.getters.getTournament.status != 'over' && store.getters.getTournament.federation === 'friendly'"
          @click="toggleAutoRounds"
          class="flex items-center gap-1 disabled:opacity-40 disabled:pointer-events-none"
          :class="store.getters.getTournament.auto_rounds ? 'success-btn' : 'secondary-btn'"
          :disabled="store.getters.getTournament.max_rounds && store.getters.getTournament.rounds.length >= store.getters.getTournament.max_rounds && !store.getters.getTournament.auto_rounds"
          v-show="!currentRound || visibleRoundIndex > 0 && visibleRoundIndex == currentRound?.index">
            <i class="fa-solid fa-rotate-right"></i>
            <p class="text-xs lg:hidden">AUTO</p>
            <p class="text-md hidden lg:block">AUTO</p>
          </button>
          <div v-if="store.getters.getTournament.status != 'over'"
          v-show="visibleRoundIndex > 0 && visibleRoundIndex === currentRound?.index">
            <button class="secondary-btn flex items-center gap-1"
            @click="hideOverGames = !hideOverGames">
              <i class="fa-solid"
              :class="!hideOverGames ? 'fa-eye' : 'fa-eye-slash'"></i>
              <p class="hidden lg:block">{{ hideOverGames ? $t('runningGames') : $t('allGames') }}</p>
            </button>
          </div>
        </div>

        <!-- Rounds list -->
        <div v-if="sortedRounds.length == 0" class="flex flex-1 justify-center items-center">
          <p class="text-xl">{{ $t('noRoundPublishedMessage') }}</p>
        </div>
        <div v-else id="rounds-list" class="flex flex-col flex-1">
          <div v-if="roundDisplayed.status === 'draft'"
          class="bg-lime-300/30 w-11/12 rounded-md text-center border border-lime-700/60 shadow mb-3 mx-auto">
            <h1 class="text-xl font-bold">Round draft (An error occured, draft not available yet, can you delete this round for us please ?)</h1>
            <p class="text-md">This round has not been published yet, only you can see this round</p>
            <p class="text-md mb-5">You can edit this round by uploading a CSV file</p>
            <div class="flex justify-around items-center">
              <div>
                <FileUpload class="mb-3" mode="basic" name="demo[]" accept=".csv" :maxFileSize="1000000" :chooseLabel="$t('browse')" :ref="el => roundFileUpload[roundDisplayed.index] = el" />
                <button class="primary-btn mb-2" @click="uploadRound(roundDisplayed)">{{ $t('confirmBtn') }}</button>
                <div class="flex-auto items-center text-indigo-200 mb-1">
                  <i class="fa-solid fa-file-import text-lg mr-2"></i>
                  <button class="text-md underline underline-offset-1" @click="downloadRoundTemplate" v-t="'registrationModal.templateDownload'"></button>
                </div>
                <div class="flex-auto items-center text-indigo-200 mb-1">
                  <i class="fa-solid fa-file-import text-lg mr-2"></i>
                  <button class="text-md underline underline-offset-1" @click="downloadTournamentPlayers" v-t="'tournament.edit.downloadPlayersAction'"></button>
                </div>
              </div>
              <button @click="resetRoundPairings(roundDisplayed)" class="warning-btn">Reset pairings</button>
              <button @click="publishRound(roundDisplayed)" class="success-btn">Publish !</button>
            </div>
          </div>
          <div v-if="!isRoundMissingResults(roundDisplayed) && hideOverGames" class="flex flex-1 justify-center w-full text-xl">
            <p>{{ $t('overRoundMessage') }}</p>
          </div>
          <div v-else>
            <div v-if="store.getters.getDisplayFormat === 'compact'" id="round.id-pairings-list" class="flex flex-col overflow-x-auto standings-pane rounded-xl">
              <div class="sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="lg:flex gap-2 overflow-x-auto">
                    <table class="w-full lg:w-1/2 text-sm font-light text-surface">
                      <thead class="border-b border-neutral-200 font-medium">
                        <tr>
                          <th scope="col" class="text-start py-1" v-t="'table'"></th>
                          <th scope="col" class="text-start py-1" v-t="'white'"></th>
                          <th scope="col" class="text-start py-1 px-1" v-t="'result'"></th>
                          <th scope="col" class="text-start py-1" v-t="'black'"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="pairing in isLargeScreen ? sortedPairings.slice(0, [Math.ceil(sortedPairings.length/2)]) : sortedPairings"
                        :key="pairing.id"
                        v-show="!hideOverGames || pairing.result == 'pending'">
                          <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" />
                        </tr>
                      </tbody>
                    </table>
                    <table v-if="isLargeScreen" class="w-1/2 text-sm font-light text-surface">
                      <thead class="border-b border-neutral-200 font-medium">
                        <tr>
                          <th scope="col" class="text-start py-1" v-t="'table'"></th>
                          <th scope="col" class="text-start py-1" v-t="'white'"></th>
                          <th scope="col" class="text-start py-1 px-1" v-t="'result'"></th>
                          <th scope="col" class="text-start py-1" v-t="'black'"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="pairing in sortedPairings.slice([Math.ceil(sortedPairings.length/2)])"
                        :key="pairing.id"
                        v-show="!hideOverGames || pairing.result == 'pending'"
                        id="round_round.id-pairing_pairing.id %>">
                          <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="store.getters.getDisplayFormat !== 'compact'" id="round.id-pairings-list">
              <div v-for="pairing in sortedPairings"
              v-show="!hideOverGames || pairing.result == 'pending'"
              id="round_round.id-pairing_pairing.id %>"
              :key="pairing.id">
                <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" />
              </div>
              <div v-show="deactivatedPlayerPairings.length > 0" class="text-center mb-4 mt-2">
                <button @click="showUnpairedPlayers = !showUnpairedPlayers" class="secondary-btn" v-t="'showAbsentPlayersBtn'"></button>
              </div>
              <div v-for="pairing in deactivatedPlayerPairings"
              v-show="showUnpairedPlayers"
              :key="pairing.id">
                <RoundPairing @toggleParticipantMenu="(pairing, color, event) => toggleParticipantMenu(pairing, color, event)" :round="roundDisplayed" :pairing="pairing" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
