<script setup>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import api from '../entrypoints/api.js';

  const store = useStore();

  const pairing = computed(() => store.getters.getUserCurrentPairing)
  const pairingResult = ref("pending");
  const userResult = computed(() => store.getters.getUserParticipant.id === store.getters.getUserCurrentPairing.white_player_id ? store.getters.getUserCurrentPairing.white_result : store.getters.getUserCurrentPairing.black_result)
  const pendingSendResult = ref(false);
  const waitSendResultTimer = ref(0);
  let timer = null;
  let previousRoundIndex = store.getters.getCurrentRound?.index || 0;

  watch((() => store.getters.getUserCurrentPairing.result), () => {
    if (pairing.value.result === "pending")
      pairingResult.value = "pending";
  })

  watch(() => store.getters.getCurrentRound, (newRound) => {
    if (newRound.index !== previousRoundIndex) {
      waitSendResultTimer.value = 0;
      if (timer) clearInterval(timer);
    }

    if (newRound.index > previousRoundIndex) {
      waitSendResultTimer.value = 30;
      timer = setInterval(() => {
        if (waitSendResultTimer.value > 0) {
          waitSendResultTimer.value -= 1;
        } else {
          clearInterval(timer);
          timer = null;
        }
      }, 1000);
    }
    previousRoundIndex = newRound.index;
  });

  async function updateResult() {
    pendingSendResult.value = true;
    const response = await api.updatePairingResult({ pairingId: store.getters.getUserCurrentPairing.id, result: pairingResult.value, override: false });
    pendingSendResult.value = false;

    if (response.error) {
      toast.add({ severity: "error", summary: response.error.message, life: 3000 });
    }
  }
</script>

<template>
  <div id="player-table-result"
  class="rounded-md md:my-6 my-4 p-2 text-center shadow-lg border-2 border-deep-blue"
  :class="{
    'bg-gray-200 text-gray-800': pairing.result == 'white_win' || pairing.result == 'white_win_by_forfeit' || pairing.result == 'exempt',
    'bg-gray-950 text-gray-200': pairing.result == 'black_win' || pairing.result == 'black_win_by_forfeit',
    'bg-gray-500': pairing.result == 'draw',
    'bg-danger text-white': pairing.result == 'conflict',
    'bg-dark-greyblue': !pairing.result || pairing.result.endsWith('_bye') || pairing.result === 'double_forfeit'
   }">

    <p v-t="`tournament.table.results.${pairing.result}`" class="text-lg m-2"></p>
    <p v-if="pairing.result === 'conflict'" class="m-2 mt-0" v-t="'tournament.table.results.conflictMessage'"></p>

    <div v-if="!userResult && !pairing.admin_result_override">
      <div v-if="store.getters.isAuthenticated">
        <div v-if="waitSendResultTimer <= 0" class="flex items-center justify-center">
          <select v-model="pairingResult"
          class="secondary-btn mx-3 my-2">
            <option disabled value='pending' v-t="'tableResultSelectDefault'">
                <div class="text-light-gray text-lg">
                  <i class="fa-solid fa-chevron-down"></i>
                </div>
            </option>
            <option value="i_won" v-t="'tableResultSelectWon'"></option>
            <option value="i_won_by_forfeit" v-t="'tableResultSelectWonByForfeit'"></option>
            <option value="i_lost" v-t="'tableResultSelectLost'"></option>
            <option value="draw" v-t="'tableResultSelectDraw'"></option>
          </select>
          <div>
            <button v-if="!pendingSendResult"
            class="primary-btn"
            :class="{ 'opacity-50': pairingResult == 'pending' }"
            @click="updateResult"
            :disabled="pairingResult == 'pending'"
            v-t="'confirmBtn'"></button>
            <ProgressSpinner v-show="pendingSendResult" style="width:50px; height:50px" strokeWidth="4" />
          </div>
        </div>
        <div v-else>
          <p>{{ $t('tournament.table.delayMessage1') }}</p>
          <p>{{ $t('tournament.table.delayMessage2', { delay: waitSendResultTimer })}}</p>
        </div>
      </div>
      <div v-else>
        <p class="mb-3">{{ $t('tableResultLoggedOutUserMessage') }}</p>
        <button @click="$router.push({ path: '/login', query: { redirect: $route.fullPath }})" class="primary-btn">{{ $t('users.login') }}</button>
      </div>
    </div>
  </div>
</template>
