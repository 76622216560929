<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  import Recaptcha from './Recaptcha.vue';

  const store = useStore();
  const router = useRouter();

  const props = defineProps({
    redirect: String,
  })
  
  const siteKey = document.querySelector('meta[name="recaptcha-site-key"]').getAttribute('content') || null;
  const email = ref('');
  const emailInput = ref(null)
  const password = ref('');
  const errorMessage = ref(null)
  const redirectSignup = computed(() => {
    return props.redirect ? { name: 'signup', query: { redirect: props.redirect }} : { name: 'signup' }
  })
  const redirectPasswordReset = computed(() => {
    return props.redirect ? { name: 'passwordReset', query: { redirect: props.redirect }} : { name: 'passwordReset' }
  })

  async function handleLogin() {
    errorMessage.value = null;

    try {
      await store.dispatch('loginUser', {
        user: {
          email: email.value,
          password: password.value,
        },
        recaptcha_token: await window.grecaptcha.execute(siteKey, { action: "user_login" }),
      })
      errorMessage.value = null;
      router.push(props.redirect || '/tournaments')
    } catch(err) {
      errorMessage.value = err.response?.data;
    }
  }

  onMounted(() => {
    emailInput.value.focus();
  })
</script>

<template>
  <Recaptcha />

  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm text-center">
      <router-link to="/" class="inline-block">
        <img class="mx-auto h-24" src="/../assets/images/logo.png" alt="Pawn Arena">
      </router-link>
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-light-grey">{{ $t('users.loginTitle') }}</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <div v-if="errorMessage" class="mb-4 p-2 border border-red-900 bg-danger text-center rounded-md">
        <p class="text-md text-light-grey">{{ errorMessage }}</p>
      </div>
      <form @submit.prevent="handleLogin"
      class="space-y-6" action="#" method="POST">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('email') }}</label>
          <div class="mt-2">
            <input ref="emailInput" v-model="email" id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" autofocus>
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('users.password') }}</label>
            <div class="text-sm">
              <router-link :to="redirectPasswordReset" class="font-semibold text-indigo-600 hover:text-indigo-500">{{ $t('users.resetPasswordCatchPhrase') }}</router-link>
            </div>
          </div>
          <div class="mt-2">
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue">
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center primary-btn">{{ $t('users.login') }}</button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        {{ $t('users.signupCatchPhrase') }}
        <router-link :to="redirectSignup" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">{{ $t('users.signup') }}</router-link>
      </p>
    </div>
  </div>
</template>
