<script setup>
  import { ref } from 'vue';
  import { useRouter } from "vue-router";
  import { useToast } from "primevue/usetoast";
  import api from '../entrypoints/api.js';

  import TournamentForm from './TournamentForm.vue';

  const router = useRouter();
  const toast = useToast();

  const trfFileUpload = ref(null);
  const pendingResponse = ref(false);

  async function uploadTrf() {
    const files = trfFileUpload.value.files;

    if (files.length === 0) {
      toast.add({ severity: "error", summary: "No file selected", life:3000});
      return;
    }

    const formData = new FormData();
    formData.append('file', files[0]);

    pendingResponse.value = true;
    api.post('/tournaments/create_from_trf', formData)
      .then(response => {
        router.push(`/tournaments/${response.data.tournament.id}`)
        toast.add({ severity: "success", summary: "Tournament successfully created", life: 3000 })
        pendingResponse.value = false;
      })
      .catch(error => {
        toast.add({ severity: 'error', summary: error.message });
        pendingResponse.value = false;
      });
  }
</script>

<template>
  <div class="flex min-h-full flex-col justify-center px-6 py-4 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <RouterLink to="/tournaments" >
        <button class="bg-skyblue text-white font-bold py-2.5 px-4 rounded-md no-underline text-right" v-t="'nav.back'"></button>
      </RouterLink>
      <h1 class="my-4 text-2xl font-bold" v-t="'tournament.form.pageTitle'"></h1>
      <div class="overflow-x-auto standings-pane bg-greyblue mt-3 p-3 rounded-xl">
        <h1 class="mb-3 text-xl font-bold">{{ $t('tournament.form.trfFileUpload') }}</h1>
        <form @submit.prevent="uploadTrf" class="flex flex-wrap items-center gap-4 justify-center">
          <FileUpload ref="trfFileUpload" v-show="!pendingResponse" mode="basic" name="demo[]" url="/api/tournaments/create_from_trf" accept=".trf" :maxFileSize="1000000" :chooseLabel="$t('tournament.form.trfFileUploadBtn')" />
          <button type="submit" v-show="!pendingResponse" class="primary-btn">{{ $t('load') }}</button>
          <ProgressSpinner v-show="pendingResponse" style="width: 50px; height: 50px" strokeWidth="6" />
        </form>
      </div>
      <div v-show="!pendingResponse" class="overflow-x-auto standings-pane bg-greyblue mt-3 p-3 rounded-xl">
        <h1 class="mb-4 text-xl font-bold" v-t="'edit'"></h1>
        <TournamentForm :isNewTournament="true" />
      </div>
    </div>
  </div>
</template>
