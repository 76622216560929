<script setup>
  import { ref, computed, onMounted, onUnmounted } from 'vue'
  import axios from 'axios'
  import { useRouter } from 'vue-router'

  import Recaptcha from './Recaptcha.vue';

  const router = useRouter();

  const props = defineProps({
    redirect: String,
  })
  const redirectLogin = computed(() => {
    return props.redirect ? { name: 'login', query: { redirect: props.redirect }} : { name: 'login' }
  })

  const siteKey = document.querySelector('meta[name="recaptcha-site-key"]').getAttribute('content') || null;

  const firstNameInput = ref(null)
  const registrationForm = ref({
    first_name: '',
    last_name: '',
    elo_standard: null,
    elo_rapid: null,
    elo_blitz: null,
    email: '',
    password: '',
    password_confirmation: '',
  })

  const fieldErrors = ref({})
  const errorMessage = ref(null)
  const isLoading = ref(false)

  async function handleSignup() {
    isLoading.value = true;
    fieldErrors.value = {};
    errorMessage.value = null;

    const recaptchaToken = await window.grecaptcha.execute(siteKey, { action: "user_create" });

    const params = {
      user: registrationForm.value,
      recaptcha_token: recaptchaToken,
    }

    axios
      .post('/users', params)
      .then((response) => {
        router.push(
          props.redirect ?
          { name: 'accountConfirmation', query: { redirect: props.redirect }} :
          { name: 'accountConfirmation' }
        )
      })
      .catch((err) => {
        errorMessage.value = err.response?.data?.message || "Something went wrong, verify your informations";
        fieldErrors.value = err.response?.data?.errors || {};
      })
      .finally(() => {
        isLoading.value = false;
      })
  };
</script>

<template>
  <Recaptcha />

  <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm text-center">
      <router-link to="/" class="inline-block">
        <img class="mx-auto h-24 w-auto" src="/../assets/images/logo.png" alt="Pawn Arena">
      </router-link>
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-light-grey">{{ $t('users.signupTitle') }}</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form id="signup_form" @submit.prevent="handleSignup"
      class="space-y-6" action="#" method="POST">
        <div class="flex justify-between gap-2">
          <div class="w-full">
            <label ref="firstNameInput" for="first_name" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('firstName') }}</label>
            <div class="mt-2">
              <input v-model="registrationForm.first_name" id="first_name" name="first_name" type="text" autocomplete="first_name" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="Jean" autofocus>
            </div>
            <div v-if="fieldErrors.first_name" class="text-sm text-red-500">{{ fieldErrors.first_name[0] }}</div>
          </div>
          <div class="w-full">
            <label for="last_name" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('lastName') }}</label>
            <div class="mt-2">
              <input v-model="registrationForm.last_name" id="last_name" name="last_name" type="text" autocomplete="last_name" class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="Prennote">
            </div>
            <div v-if="fieldErrors.last_name" class="text-sm text-red-500">{{ fieldErrors.last_name[0] }}</div>
          </div>
        </div>
        <div>
          <label for="" class="block text-md font-medium leading-6 text-light-grey">{{ $t('rating') }} ({{ $t('tournament.form.optional') }})</label>
          <div class="flex justify-between gap-2">
            <div class="w-full">
              <label for="elo_standard" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('tournament.form.standard') }}</label>
              <div class="mt-2">
                <input v-model="registrationForm.elo_standard" id="elo_standard" name="elo_standard" type="text" autocomplete="elo_standard" class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="0">
              </div>
              <div v-if="fieldErrors.elo_standard" class="text-sm text-red-500">{{ fieldErrors.elo_standard[0] }}</div>
            </div>
            <div class="w-full">
              <label for="elo_rapid" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('tournament.form.rapid') }}</label>
              <div class="mt-2">
                <input v-model="registrationForm.elo_rapid" id="elo_rapid" name="elo_rapid" type="text" autocomplete="elo_rapid" class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="0">
              </div>
              <div v-if="fieldErrors.elo_rapid" class="text-sm text-red-500">{{ fieldErrors.elo_rapid[0] }}</div>
            </div>
            <div class="w-full">
              <label for="elo_blitz" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('tournament.form.blitz') }}</label>
              <div class="mt-2">
                <input v-model="registrationForm.elo_blitz" id="elo_blitz" name="elo_blitz" type="text" autocomplete="elo_blitz" class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="0">
              </div>
              <div v-if="fieldErrors.elo_blitz" class="text-sm text-red-500">{{ fieldErrors.elo_blitz[0] }}</div>
            </div>
          </div>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('email') }}</label>
          <div class="mt-2">
            <input v-model="registrationForm.email" id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="jean.prennote@gmail.com">
          </div>
          <div v-if="fieldErrors.email" class="text-sm text-red-500">{{ fieldErrors.email[0] }}</div>
        </div>
        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('users.password') }}</label>
          </div>
          <div class="mt-2">
            <input v-model="registrationForm.password" id="password" name="password" type="password" autocomplete="new-password" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="******">
          </div>
          <div v-if="fieldErrors.password" class="text-sm text-red-500">{{ fieldErrors.password[0] }}</div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password_confirmation" class="block text-sm font-medium leading-6 text-light-grey">{{ $t('users.confirmationPassword') }}</label>
          </div>
          <div class="mt-2">
            <input v-model="registrationForm.password_confirmation" id="password_confirmation" name="password" type="password" autocomplete="new-password" required class="block w-full rounded-md border-0 py-1.5 text-light-grey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-deep-greyblue" placeholder="******">
          </div>
          <div v-if="fieldErrors.password_confirmation" class="text-sm text-red-500">{{ fieldErrors.password_confirmation[0] }}</div>
        </div>


        <p v-if="errorMessage" class="bg-danger text-white p-1 rounded text-center">{{ errorMessage }}</p>
        <button type="submit"
          class="g-recaptcha flex w-full justify-center primary-btn"
          @click.prevent="handleSignup">
          {{ $t('users.signup') }}
        </button>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        {{ $t('users.loginCatchPhrase') }}
        <router-link :to="redirectLogin" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">{{ $t('users.login') }}</router-link>
      </p>
    </div>
  </div>
</template>
